import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Track from "../../components/tracks/Track.js";

class TrackView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tracks: []
    };
  }

  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/Tracks?api_key=keyY1aCVUGd6KIZKQ&view=grid"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          tracks: data.records
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: true
    };

    return (
      <div class="track-view-container">
        <Slider {...settings}>
          {this.state.tracks.map(track => (
            <Track {...track.fields} />
          ))}
        </Slider>
      </div>
    );
  }
}

export default TrackView;
