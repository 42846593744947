import React from "react";

import { Link } from "react-router-dom";

class NewsPreview extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <Link
          className="news-preview-container"
          to={"/news?id=" + this.props.newsID}
          target=""
        >
          <div class="d-flex flex-column align-items-start justify-content-end news-preview-description">
            <img
              class="news-preview-image rounded-0"
              src={this.props.imageSmall[0].url}
              alt="Card image"
            />
            <p class="news-preview-date">{this.props.date}</p>
            <p class="news-preview-caption">{this.props.headline}</p>
            <p />
          </div>
        </Link>
      </div>
    );
  }
}

export default NewsPreview;
