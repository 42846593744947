import React from "react";

class LeaderboardsDetailed extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <table class="table table-dark table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">DRIVER</th>
              <th scope="col">S1</th>
              <th scope="col">S2</th>
              <th scope="col">S3</th>
              <th scope="col">LAPTIME</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">12</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">13</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">14</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">15</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">16</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">17</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">18</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">19</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">20</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">21</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">22</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
            <tr>
              <th scope="row">23</th>
              <td>Username</td>
              <td>7.32</td>
              <td>13.84</td>
              <td>11.69</td>
              <td>32.85</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default LeaderboardsDetailed;
