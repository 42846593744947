import React from "react";

class BackgroundVideo extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.src !== this.props.src;
  }

  render() {
    return (
      <div
        className={this.props.className}
        // React fails to parse 'muted' when using standard <video> tags which are required for autoplay to allowed by the browser
        // Use dangerous setting
        dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            preload="metadata"
            class="center-video"
            src=${this.props.src}
          />,
        `
        }}
      />
    );
  }
}

export default BackgroundVideo;
