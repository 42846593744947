import React from "react";

import FAQItem from "../faq/FAQItem.js";

class FAQSection extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <p className="faq-section-header">{this.props.name}</p>
        {this.props.items &&
          this.props.items.map(itemID => (
            <FAQItem className="faq-item-container" itemID={itemID} />
          ))}
      </div>
    );
  }
}

export default FAQSection;
