import React from "react";

class Kart extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <div class="kart-container">
          <h1 class="kart-name-header">{this.props.name}</h1>
          <br />
          <h2 class="kart-location-header">{this.props.location}</h2>
          <p />
          {this.props.image && (
            <img class="fit-image" src={this.props.image[0].url} />
          )}
          <p />
          <h3 class="kart-website">{this.props.url}</h3>
        </div>
      </div>
    );
  }
}

export default Kart;
