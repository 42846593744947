import React from "react";

import FAQSection from "../../components/faq/FAQSection.js";

class FAQView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: []
    };
  }

  componentDidMount() {
    // TODO: optimise this to prevent a request for each faq item. Not enough time today.
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/FAQSections?api_key=keyY1aCVUGd6KIZKQ&view=grid"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          sections: data.records
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    return (
      <div className="faq-view-container">
        <h1 className="faq-header">FAQ</h1>
        {this.state.sections.map(section => (
          <FAQSection className="faq-section-container" {...section.fields} />
        ))}
      </div>
    );
  }
}

export default FAQView;
