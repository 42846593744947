import React from "react";

import logo_black_delta from "../../img/logo_bd.png";
import logo_steam from "../../img/logo_steam.png";
import logo_unreal from "../../img/logo_unreal.png";
import logo_oculus from "../../img/logo_oculus.png";

const Footer = ({}) => (
  <footer className="home-footer-container">
    <div className="d-inline-flex flex-wrap justify-content-center">
      <div className="col debug-border">
        <a href="http://www.unrealengine.com" target="_blank">
          <img src={logo_unreal} className="home-footer-logo" />
        </a>
      </div>
      <div className="col debug-border">
        <a
          href="https://store.steampowered.com/app/406350/KartKraft/"
          target="_blank"
        >
          <img src={logo_steam} className="home-footer-logo" />
        </a>
      </div>
      <div className="col debug-border">
        <a href="http://www.oculus.com" target="_blank">
          <img src={logo_oculus} className="home-footer-logo" />
        </a>
      </div>
    </div>
    <p className="home-footer-copyright home-body-light">
      © {new Date().getFullYear()}. Motorsport Games Inc. All Rights Reserved. KartKraft,
      kartkraftgame.com and the KartKraft logo are trademarks of Motorsport Games Inc.
    </p>
  </footer>
);

export default Footer;
