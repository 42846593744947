import React from "react";

class RoadmapFeature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      details: undefined
    };
  }

  // TODO: Move api key to environment file
  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/RoadmapFeatures/" +
        this.props.featureID +
        "?api_key=keyY1aCVUGd6KIZKQ"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          details: data.fields
        });
      })
      .catch(err => {
        // Error
      });
  }
  render() {
    return (
      <div>
        {this.state.details &&
          this.state.details.image &&
          0 < this.state.details.image.length && (
            <img src={this.state.details.image[0].url} className="fit-image" />
          )}
        <p class="list-group-item list-group-item-action rounded-0 roadmap-release-feature">
          {this.state.details !== undefined && this.state.details.name}
        </p>
      </div>
    );
  }
}

export default RoadmapFeature;
