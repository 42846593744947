import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Kart from "../../components/karts/Kart.js";

class KartView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      karts: []
    };
  }

  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/Karts?api_key=keyY1aCVUGd6KIZKQ&view=grid"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          karts: data.records
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: true
    };

    return (
      <div class="kart-view-container">
        <Slider {...settings}>
          {this.state.karts.map(kart => (
            <Kart {...kart.fields} />
          ))}
        </Slider>
      </div>
    );
  }
}

export default KartView;
