import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoEmbedding = ({ videoID }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://www.youtube.com/embed/${videoID}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  );
};

class NewsArticle extends React.Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnFocus: true
    };

    var imageHeader;
    if (this.props.videoID) {
      imageHeader = <VideoEmbedding videoID={this.props.videoID} />;
    } else if (this.props.carousel) {
      imageHeader = (
        <Slider {...settings}>
          {this.props.carousel &&
            this.props.carousel.map(image => (
              <img class="fit-image" src={image.url} alt="" />
            ))}
        </Slider>
      );
    } else {
      imageHeader = (
        <img className="fit-image" src={this.props.imageLarge[0].url} />
      );
    }

    return (
      <div>
        <p className="news-article-date">{this.props.date}</p>
        <br />
        <h1 className="news-article-header">{this.props.headline}</h1>
        {imageHeader}
        <p />
        <div
          className={this.props.className}
          dangerouslySetInnerHTML={{
            __html: this.props.content
          }}
        />
      </div>
    );
  }
}

export default NewsArticle;
