import React from "react";

import QueryString from "query-string";

import NewsArticle from "../../components/news/NewsArticle.js";

class NewsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      article: undefined
    };
  }

  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/News/" +
        QueryString.parse(this.props.location.search).id +
        "?api_key=keyY1aCVUGd6KIZKQ"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          article: data.fields
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    return (
      <div className="news-view-container">
        {this.state.article && (
          <NewsArticle
            className="news-article-container"
            {...this.state.article}
          />
        )}
      </div>
    );
  }
}

export default NewsView;
