import React from "react";
import { Route, NavLink, BrowserRouter } from "react-router-dom";

import HomeIntro from "./HomeIntro.js";
import HomeNews from "./HomeNews.js";
import HomeTestimonial from "./HomeTestimonial.js";
import HomeRoadmap from "./HomeRoadmap.js";
import HomeSignup from "./HomeSignup.js";

const HomeView = ({ match }) => (
  <div>
    <HomeIntro />
    <HomeNews />
    <HomeTestimonial testimonialID="recOjLtzOEx8ftGL5" />
    <HomeRoadmap />
    <HomeTestimonial testimonialID="rec8atp0vYZAcL175" />
    <HomeSignup />
  </div>
);

export default HomeView;
