import React from "react";

import jsonp from "jsonp";
import toQueryString from "to-querystring";
import jQuery from "jquery";

import formToJSON from "../../utils/formUtils.js";

// Define a const object to represent an enum for the status of the signup submission
const eSignupStatus = {
  sending: "sending",
  success: "success",
  error: "error"
};

// TODO: Move to environment file before deployment
const API_KEY = "55c51098b89b432c247cd6d49"; //u
const AUDIENCE_ID = "40d7e458e7"; //i

const signupURL = `https://kartsim.us2.list-manage.com/subscribe/post?u=${API_KEY}&id=${AUDIENCE_ID}&`;

// Convert the URL to an AJAX query to exploit the undocumented mailchimp JSON API
const getAjaxURL = url => url.replace("/post?", "/post-json?");

class MailchimpSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "init",
      message: null,
      counter: 0
    };

    this.runningTotal = 0;
  }

  subscribe = async e => {
    // This prevents the page from refreshing when the form is submitted
    e.preventDefault();

    this.setState({
      status: eSignupStatus.sending,
      message: null
    });

    let ajaxURL = getAjaxURL(signupURL);

    const jsonElements = formToJSON(e.target.elements);

    let params = toQueryString(jsonElements);

    // We can't use fetch here as it does not support CORS
    await jsonp(
      `${ajaxURL}${params}`,
      {
        param: "c" // required for callback
      },
      (err, data) => {
        if (err) {
          this.setState({
            status: eSignupStatus.error,
            message: "Error sending request"
          });
        } else {
          if (data.result !== eSignupStatus.success) {
            this.setState({
              status: eSignupStatus.error,
              message: data.msg
            });
          } else {
            this.setState({
              status: eSignupStatus.success,
              message: data.msg
            });
          }
        }
      }
    );
  };

  render() {
    const signedUp = this.state.status == eSignupStatus.success;

    if (!signedUp) {
      return (
        <form onSubmit={this.subscribe}>
          <div class="field">
            <input
              className="signup-form-input"
              type="text"
              name="FNAME"
              required
            />
            <br />
            <label>FIRST NAME</label>
          </div>
          <div class="field">
            <input
              className="signup-form-input"
              type="text"
              name="LNAME"
              required
            />
            <br />
            <label>LAST NAME</label>
          </div>
          <div class="field">
            <input
              className="signup-form-input"
              type="email"
              name="EMAIL"
              required
            />
            <br />
            <label>EMAIL ADDRESS</label>
          </div>
          <button className="signup-form-button">SIGN UP</button>
          <div
            className="home-body-light-bold"
            dangerouslySetInnerHTML={{ __html: this.state.message }}
          />
        </form>
      );
    } else {
      return (
        <div>
          <div
            className="home-body-light-bold"
            dangerouslySetInnerHTML={{ __html: this.state.message }}
          />
        </div>
      );
    }
  }
}

export default MailchimpSignup;
