import React from "react";

const VimeoEmbedding = ({ vimeoID }) => {
  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={`https://player.vimeo.com/video/${vimeoID}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  );
};

class Track extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <div class="track-container">
          <h1 class="track-name-header">{this.props.name}</h1>
          <br />
          <h2 class="track-location-header">{this.props.location}</h2>
          <p />
          {this.props.vimeoID && (
            <VimeoEmbedding vimeoID={this.props.vimeoID} />
          )}
          {this.props.image && (
            <img class="fit-image" src={this.props.image[0].url} />
          )}
          <p />
          <h3 class="track-website">{this.props.url}</h3>
          <p class="track-body-description">{this.props.description}</p>
        </div>
      </div>
    );
  }
}

export default Track;
