import React, { Component } from "react";

import { Route, NavLink, Router } from "react-router-dom";
import createHistory from "history/createBrowserHistory";

import { CSSTransition, TransitionGroup } from "react-transition-group";

// Views
import MainNavbar from "./components/navbar/MainNavbar.js";
import Footer from "./components/footer/Footer.js";

import HomeView from "./views/home/HomeView.js";
import LeaderboardsView from "./views/leaderboards/LeaderboardsView.js";
import NewsView from "./views/news/NewsView.js";
import TrackView from "./views/tracks/TrackView.js";
import KartView from "./views/karts/KartView.js";
import FAQView from "./views/faq/FAQView.js";

import "./App.css";

const history = createHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Router history={history}>
        <div>
          <MainNavbar />
          <Route exact path="/" component={HomeView} />
          <Route path="/leaderboards" component={LeaderboardsView} />
          <Route path="/news" component={NewsView} />
          <Route path="/karts" component={KartView} />
          <Route path="/tracks" component={TrackView} />
          <Route path="/faq" component={FAQView} />
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
