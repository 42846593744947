import React from "react";

import RoadmapFeature from "./RoadmapFeature.js";

class RoadmapRelease extends React.Component {
  render() {
    return (
      <div className={this.props.className}>
        <div class="list-group">
          <p class="list-group-item list-group-item-action rounded-0 roadmap-release-header">
            {this.props.releaseName}
          </p>
          <p class="list-group-item list-group-item-action rounded-0">
            <p className="roadmap-release-date-label">ETA</p>
            <p className="roadmap-release-date">{this.props.releaseDate}</p>
          </p>
          {this.props.features.map(featureID => (
            <RoadmapFeature featureID={featureID} />
          ))}
        </div>
      </div>
    );
  }
}

export default RoadmapRelease;
