import React from "react";

import Logotype from "../../img/logotype_kk.png";
import Brandmark from "../../img/kartkraft-brandmark.png";

import { NavLink } from "react-router-dom";

class MainNavbar extends React.Component {
  render() {
    return (
      <div>
        <nav class="navbar fixed-top navbar-expand-lg navbar-dark main-navbar bg-dark">
          <NavLink class="navbar-brand" to="/">
            <img src={Brandmark} width="30" height="30" alt="" />
            <img
              src={Logotype}
              width="auto"
              height="16"
              alt=""
              className="brand-padding"
            />
          </NavLink>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon" />
          </button>
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <NavLink
              to="/tracks"
              className="nav-link"
              activeClassName="nav-link-active"
            >
              TRACKS
            </NavLink>
            <NavLink
              to="/karts"
              className="nav-link"
              activeClassName="nav-link-active"
            >
              KARTS
            </NavLink>
            <NavLink
              to="/faq"
              className="nav-link"
              activeClassName="nav-link-active"
            >
              FAQ
            </NavLink>
            <a
              class="nav-link"
              href="https://discord.gg/a27PAe4"
              target="_blank"
            >
              COMMUNITY
            </a>
            <button class="nav-link" data-xpaystation-widget-open>
              BUY NOW
            </button>
            <a
              class="nav-link"
              href="mailto:contact@blackdelta.com.au"
              target="_blank"
            >
              CONTACT
            </a>
          </div>
        </nav>
      </div>
    );
  }
}

export default MainNavbar;
