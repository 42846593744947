import React from "react";

import { Link } from "react-router-dom";

class NewsPreviewHero extends React.Component {
  render() {
    let Style = {
      backgroundImage: "url(" + this.props.imageLarge[0].url + ")",
      backgroundSize: "cover",
      backgroundPosition: "bottom center",
      width: "100%",
      height: "100%"
    };

    return (
      <div className={this.props.className}>
        <Link to={"/news?id=" + this.props.newsID} target="">
          <div style={Style}>
            <div class="card-img-overlay d-flex flex-column align-items-end justify-content-end mt-3 mb-3">
              <p class="news-preview-hero-date">{this.props.date}</p>
              <p class="news-preview-hero-headline">{this.props.headline}</p>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default NewsPreviewHero;
