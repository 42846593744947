import React from "react";

class FAQItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: undefined
    };
  }

  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/FAQItemS/" +
        this.props.itemID +
        "?api_key=keyY1aCVUGd6KIZKQ"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          item: data.fields
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    return (
      <div>
        {this.state.item && (
          <div className={this.props.className}>
            <p className="faq-item-question">{this.state.item.question}</p>
            <p
              className="faq-item-answer"
              dangerouslySetInnerHTML={{
                __html: this.state.item.answer
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default FAQItem;
