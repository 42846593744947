import React from "react";

import LeaderboardsDetailed from "../../components/leaderboards/LeaderboardsDetailed.js";

const LeaderboardsView = ({ match }) => (
  <div class="d-flex leaderboard-wrapper">
    <div class="bg-dark border-right class-select" id="sidebar-wrapper">
      <div class="list-group list-group-flush">
        <a
          href="#"
          class="list-group-item list-group-item-action bg-dark text-white"
        >
          X30
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action bg-dark text-white"
        >
          KA100
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action bg-dark text-white"
        >
          KZ2
        </a>
      </div>
    </div>
    <div class="bg-dark border-right class-select" id="sidebar-wrapper">
      <div class="list-group list-group-flush">
        <a
          href="#"
          class="list-group-item list-group-item-action bg-dark text-white"
        >
          ATLANTA
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action bg-dark text-white"
        >
          GEELONG
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action bg-dark text-white"
        >
          GKCV
        </a>
        <a
          href="#"
          class="list-group-item list-group-item-action bg-dark text-white"
        >
          PFi
        </a>
      </div>
    </div>
    <LeaderboardsDetailed className="leaderboard-details" />
  </div>
);

export default LeaderboardsView;
