import React from "react";

import NewsPreview from "../../components/news/NewsPreview.js";
import NewsPreviewHero from "../../components/news/NewsPreviewHero.js";

class HomeNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsItems: []
    };
  }

  // TODO: Move api key to environment file
  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/News?api_key=keyY1aCVUGd6KIZKQ&view=grid&maxRecords=5"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          newsItems: data.records
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    if (this.state.newsItems && 0 < this.state.newsItems.length) {
      let heroLarge = this.state.newsItems[0];
      let heroSmall = this.state.newsItems[1];
      let previews = this.state.newsItems.slice(2, this.state.newsItems.length);

      return (
        <div className="home-news-container">
          <p className="home-title-light">RECENT NEWS</p>
          <div class="card-deck">
            {heroLarge !== undefined && (
              <NewsPreviewHero
                className="news-preview-hero-container col-xl-7"
                {...heroLarge.fields}
                newsID={heroLarge.id}
              />
            )}
            {heroSmall !== undefined && (
              <NewsPreviewHero
                className="news-preview-hero-container col-xl-5"
                {...heroSmall.fields}
                newsID={heroSmall.id}
              />
            )}
          </div>
          <div class="card-deck">
            {previews.map(newsItem => (
              <NewsPreview
                className="news-preview col-xl-4"
                {...newsItem.fields}
                newsID={newsItem.id}
              />
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="home-news-container">
          <p className="home-title-light">RECENT NEWS</p>
        </div>
      );
    }
  }
}

export default HomeNews;
