import React from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import RoadmapRelease from "../../components/roadmap/RoadmapRelease.js";

class HomeRoadmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      releases: []
    };
  }

  // TODO: Move api key to environment file
  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/Roadmap?api_key=keyY1aCVUGd6KIZKQ&view=grid"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          releases: data.records
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      pauseOnFocus: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="home-roadmap-container">
        <p className="home-title-light">ROADMAP</p>
        <Slider {...settings}>
          {this.state.releases.map(release => (
            <RoadmapRelease
              className="roadmap-release-container"
              {...release.fields}
            />
          ))}
        </Slider>
      </div>
    );
  }
}

export default HomeRoadmap;
