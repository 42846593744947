import React from "react";

import MailchimpSignup from "../../components/mailchimp/MailchimpSignup.js";

const HomeSignup = ({}) => (
  <div className="home-signup-container">
    <div className="d-flex justify-content-center">
      <div className="col-md-6">
        <p className="home-title-light center-text">STAY AHEAD. SIGN UP.</p>
        <p />
        <MailchimpSignup />
      </div>
    </div>
  </div>
);

export default HomeSignup;
