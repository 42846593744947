import React from "react";

import CaptionedBackground from "../../components/CaptionedBackground.js";

class HomeTestimonial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonial: undefined
    };
  }

  // TODO: Move api key to environment file
  componentDidMount() {
    fetch(
      "https://api.airtable.com/v0/appqXILPecp1P5a5R/Testimonials/" +
        this.props.testimonialID +
        "?api_key=keyY1aCVUGd6KIZKQ"
    )
      .then(resp => resp.json())
      .then(data => {
        this.setState({
          testimonial: data.fields
        });
      })
      .catch(err => {
        // Error
      });
  }

  render() {
    if (this.state.testimonial) {
      return (
        <CaptionedBackground
          caption={this.state.testimonial.caption}
          captionAlignmentVertical={
            this.state.testimonial.captionAlignmentVertical
          }
          captionAlignmentHorizontal={
            this.state.testimonial.captionAlignmentHorizontal
          }
          captionSubtext={this.state.testimonial.captionSubtext}
          imageURL={this.state.testimonial.image[0].url}
        />
      );
    } else {
      return <div />;
    }
  }
}

export default HomeTestimonial;
