import React from "react";

class CaptionedBackground extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let Style = {
      backgroundImage: "url(" + this.props.imageURL + ")",
      backgroundSize: "cover",
      height: "100%"
    };

    return (
      <div className="home-testimonial-container">
        <div className="home-testimonial-background" style={Style}>
          <div className="home-testimonial-overlay">
            <div
              className={
                "d-flex caption-container" +
                (this.props.captionAlignmentVertical == "top"
                  ? " align-items-start"
                  : " align-items-end") +
                (this.props.captionAlignmentHorizontal == "left"
                  ? " justify-content-start"
                  : " justify-content-end")
              }
            >
              <div className="col-10 col-md-6">
                <p className="captioned-background-caption">
                  {this.props.caption}
                </p>
                <p className="captioned-background-subtext">
                  {this.props.captionSubtext}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CaptionedBackground;
