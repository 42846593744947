import React from "react";

import BackgroundVideo from "../../components/BackgroundVideo.js";

import Video from "../../img/intro-splash.mp4";

const HomeIntro = ({}) => (
  <div>
    <BackgroundVideo className="intro-video-container" src={Video} />
  </div>
);

export default HomeIntro;
